/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'enter': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.183.332a.982.982 0 00-1.39 0L5.088 4.04a.982.982 0 101.39 1.39l2.03-2.03v10.043H3.693a.983.983 0 000 1.965H8.6c.615 0 1.106-.122 1.435-.459.327-.335.436-.824.436-1.413V3.4l2.03 2.03a.982.982 0 101.39-1.39L10.182.332zM5.275 5.24c.28.28.733.28 1.013 0l2.485-2.485L6.288 5.24a.716.716 0 01-1.013 0m-2.06 9.717a.713.713 0 00.479.183H8.6c1.178 0 1.604-.473 1.604-1.605V2.755v10.78c0 1.132-.426 1.605-1.604 1.605H3.694a.713.713 0 01-.478-.183zM12.69 5.24a.716.716 0 001.013-1.012.716.716 0 01-1.013 1.012zm-7.414 0l-.001-.002z" _fill="#fff"/>'
  }
})
